import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_TEMPLATE = { action: 'change', subject: 'Templates' }

  const fields = {
    setting_fulfill_template_order_ready_for_pick_up: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 6,
      },
    },
    setting_fulfill_template_order_was_not_picked_up: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 6,
      },
    },
    setting_fulfill_template_order_was_not_returned_on: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 6,
      },
    },

    setting_fulfill_template_returned_order_has_missing_items: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 6,
      },
    },
    setting_fulfill_template_returned_order_has_damaged_items: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 6,
      },
    },

  }

  return {
    fields,
    ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_TEMPLATE,
  }
}
