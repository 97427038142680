<template>
  <div>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Order Ready for pick up') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="fieldItem in ['setting_fulfill_template_order_ready_for_pick_up']"
          :key="fieldItem"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[fieldItem].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Order Wasn’t picked up') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['setting_fulfill_template_order_was_not_picked_up']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Order Wasn’t returned on time') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['setting_fulfill_template_order_was_not_returned_on']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Returned Order has missing items') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['setting_fulfill_template_returned_order_has_missing_items']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Returned Order has damaged items') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['setting_fulfill_template_returned_order_has_damaged_items']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '@/views/settings/fulfillment/templates/templatesConfig'

export default {
  name: 'Skeletons',
  components: { BCol, BSkeleton, BRow },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
<style>

</style>
