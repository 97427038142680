<template>
  <div>
    <skeleton v-if="loading" />
    <b-form v-else>
      <validation-observer
        ref="templateCreateVal"
      >
        <b-row>
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <label class="font-weight-bolder text-body font-medium-5 mb-1">{{ $t('Order Ready for pick up') }}</label>
              <feather-icon
                v-if="organizationRequiresEditConfirmation"
                v-b-tooltip.noninteractive.hover.bottom
                :title="$t('Edit')"
                :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
                size="36"
                class="ml-1 cursor-pointer"
                @click="isEditAble()"
              />
            </div>
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_template_order_ready_for_pick_up']"
              :key="field"
              v-model="templatesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="12">
            <label class="font-weight-bolder text-body font-medium-5 mb-1">{{ $t('Order Wasn’t picked up') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_template_order_was_not_picked_up']"
              :key="field"
              v-model="templatesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="12">
            <label class="font-weight-bolder text-body font-medium-5 mb-1">{{ $t('Order Wasn’t returned on time') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_template_order_was_not_returned_on']"
              :key="field"
              v-model="templatesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="12">
            <label class="font-weight-bolder text-body font-medium-5 mb-1">{{ $t('Returned Order has missing items') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_template_returned_order_has_missing_items']"
              :key="field"
              v-model="templatesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="12">
            <label class="font-weight-bolder text-body font-medium-5 mb-1">{{ $t('Returned Order has damaged items') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_template_returned_order_has_damaged_items']"
              :key="field"
              v-model="templatesForm[field]"
              v-bind="getProps(field)"
              class="mb-0"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="routeLink"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import Skeleton from '@/views/settings/fulfillment/templates/components/Skeletons.vue'
import config from '@/views/settings/fulfillment/templates/templatesConfig'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import store from '@/store'

export default {
  name: 'TemplatesForm',
  components: {
    ErrorAlert, Skeleton, BCol, BButton, BForm, BRow, ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      error: {},
      loading: true,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    templatesForm() {
      return this.$store.state[this.MODULE_NAME].templatesForm
    },
    templatesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].templateFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.templatesForm) !== this.templatesFormClone
    },
  },
  created() {
    this.load()
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    routeLink() {
      this.$router.push({ name: 'settings' })
    },
    load() {
      this.template = {}
      this.templateClone = {}
      this.$store.dispatch(`${this.MODULE_NAME}/get`).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_TEMPLATE_FORM_CLONE`, data)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
    submit() {
      this.onSubmit = true

      this.$refs.templateCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.templatesForm
            const templateForm = this.mappingFields(Object.keys(this.fields), data)
            this.sendNotification(this, { ...templateForm }, `${this.MODULE_NAME}/update`)
              .then(() => {
                this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
                this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_TEMPLATE_FORM_CLONE`, data)
                // this.$router.replace({
                //   name: 'settings-fulfillment-labels',
                // })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.templateCreateVal)
          }
        })
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.fields[fieldName],
        },
        name: fieldName,
      }
      return dataOF
    },
    loader() {
      this.$refs.templateCreateVal.reset()
      const smth = JSON.parse(this.templatesFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
  },

  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'templates'

    const { fields, ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_TEMPLATE } = config()
    return {
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      ACCESS_ABILITY_FOR_SYSTEM_FULFILLMENT_TEMPLATE,
    }
  },
}
</script>
<style >

</style>
